<template>
    <svg :width="size" :height="size-4" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0293 20.0002L22.0003 4.33203L12.9719 15.3228L11.0293 20.0002Z" fill="url(#paint0_linear_71_10992)"/>
        <path d="M12.9707 15.3243L21.999 4.33203H17.6343L12.9707 15.3243Z" fill="url(#paint1_linear_71_10992)"/>
        <path d="M15.3044 4.32279L14.4541 1.27539L17.635 4.32859L15.3044 4.32279Z" fill="url(#paint2_linear_71_10992)"/>
        <path d="M11 4.32284L14.4545 1.27539L15.3048 4.32284H11Z" fill="url(#paint3_linear_71_10992)"/>
        <path d="M12.9717 15.321L15.3043 4.32422L17.6342 4.32995L12.9717 15.321Z" fill="url(#paint4_linear_71_10992)"/>
        <path d="M11.02 0L14.4542 1.27608L10.9995 4.32353L7.5459 1.27436L11.02 0Z" fill="url(#paint5_linear_71_10992)"/>
        <path d="M15.3052 4.32227H11L12.9718 15.3194L15.3052 4.32227Z" fill="url(#paint6_linear_71_10992)"/>
        <path d="M14.454 1.27613L11.0205 0L17.6548 0.00231403L14.454 1.27613Z" fill="url(#paint7_linear_71_10992)"/>
        <path d="M20.001 1.26562L17.6348 4.33095H21.9976L20.001 1.26562Z" fill="url(#paint8_linear_71_10992)"/>
        <path d="M14.4531 1.27543L17.6347 4.32966L20.0013 1.2663L17.6543 0.00195312L14.4531 1.27543Z" fill="url(#paint9_linear_71_10992)"/>
        <path d="M11.0293 19.9993L0.000600815 4.33008L9.02903 15.3209L11.0293 19.9993Z" fill="url(#paint10_linear_71_10992)"/>
        <path d="M9.02832 15.3224L4.95911e-05 4.33008H4.3647L9.02832 15.3224Z" fill="url(#paint11_linear_71_10992)"/>
        <path d="M6.69464 4.32083L7.54492 1.27344L4.36398 4.32664L6.69464 4.32083Z" fill="url(#paint12_linear_71_10992)"/>
        <path d="M11 4.32088L7.5455 1.27344L6.69523 4.32088H11Z" fill="url(#paint13_linear_71_10992)"/>
        <path d="M9.02734 15.319L6.69473 4.32227L4.36484 4.328L9.02734 15.319Z" fill="url(#paint14_linear_71_10992)"/>
        <path d="M6.69484 4.32031H11L9.02823 15.3175L6.69484 4.32031Z" fill="url(#paint15_linear_71_10992)"/>
        <path d="M7.54538 1.27382L11.0205 0H4.34457L7.54538 1.27382Z" fill="url(#paint16_linear_71_10992)"/>
        <path d="M1.99703 1.26367L4.36328 4.329H0.000444412L1.99703 1.26367Z" fill="url(#paint17_linear_71_10992)"/>
        <path d="M7.54492 1.27348L4.3633 4.3277L1.99678 1.26434L4.34372 0L7.54492 1.27348Z" fill="url(#paint18_linear_71_10992)"/>
        <path d="M12.9714 15.319L10.9997 4.32227L9.02734 15.3175L11.0285 19.9982L12.9714 15.319Z" fill="url(#paint19_diamond_71_10992)"/>
        <defs>
        <linearGradient id="paint0_linear_71_10992" x1="18.3443" y1="3.79886" x2="15.1768" y2="19.7342" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint1_linear_71_10992" x1="14.0796" y1="13.9506" x2="22.2522" y2="5.15679" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint2_linear_71_10992" x1="16.575" y1="1.17149" x2="16.1513" y2="4.34286" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint3_linear_71_10992" x1="13.9428" y1="-0.0364023" x2="13.2541" y2="5.44663" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7E00"/>
        <stop offset="1" stop-color="#FFA800"/>
        </linearGradient>
        <linearGradient id="paint4_linear_71_10992" x1="16.159" y1="-0.409398" x2="9.00178" y2="16.6931" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7E00"/>
        <stop offset="1" stop-color="#FFA800"/>
        </linearGradient>
        <linearGradient id="paint5_linear_71_10992" x1="11" y1="0" x2="11" y2="5.34336" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFAE00"/>
        <stop offset="1" stop-color="#FFF4E1"/>
        </linearGradient>
        <linearGradient id="paint6_linear_71_10992" x1="13.1526" y1="4.32227" x2="13.1526" y2="15.3194" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF7C00"/>
        </linearGradient>
        <linearGradient id="paint7_linear_71_10992" x1="15.4439" y1="-0.0434259" x2="15.4078" y2="1.30479" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint8_linear_71_10992" x1="19.8162" y1="1.26562" x2="18.7163" y2="7.71938" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFCE6F"/>
        <stop offset="1" stop-color="#FF6600"/>
        </linearGradient>
        <linearGradient id="paint9_linear_71_10992" x1="17.6926" y1="-3.09755" x2="17.6926" y2="4.32966" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFAE00"/>
        <stop offset="1" stop-color="#FFF4E1"/>
        </linearGradient>
        <linearGradient id="paint10_linear_71_10992" x1="3.67588" y1="3.79686" x2="6.82848" y2="19.7396" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint11_linear_71_10992" x1="7.9194" y1="13.9486" x2="-0.253222" y2="5.15484" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint12_linear_71_10992" x1="5.42402" y1="1.16954" x2="5.84769" y2="4.3409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint13_linear_71_10992" x1="8.05722" y1="-0.0383555" x2="8.74595" y2="5.44468" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7E00"/>
        <stop offset="1" stop-color="#FFA800"/>
        </linearGradient>
        <linearGradient id="paint14_linear_71_10992" x1="5.84002" y1="-0.411351" x2="12.9972" y2="16.6912" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7E00"/>
        <stop offset="1" stop-color="#FFA800"/>
        </linearGradient>
        <linearGradient id="paint15_linear_71_10992" x1="8.84742" y1="4.32031" x2="8.84742" y2="15.3175" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF7C00"/>
        </linearGradient>
        <linearGradient id="paint16_linear_71_10992" x1="6.5693" y1="-0.0433472" x2="6.60504" y2="1.30244" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC900"/>
        <stop offset="1" stop-color="#FF5B00"/>
        </linearGradient>
        <linearGradient id="paint17_linear_71_10992" x1="2.18186" y1="1.26367" x2="3.28173" y2="7.71743" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFCE6F"/>
        <stop offset="1" stop-color="#FF6600"/>
        </linearGradient>
        <linearGradient id="paint18_linear_71_10992" x1="4.30547" y1="-3.0995" x2="4.30547" y2="4.32771" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFAE00"/>
        <stop offset="1" stop-color="#FFF4E1"/>
        </linearGradient>
        <radialGradient id="paint19_diamond_71_10992" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11 13.3322) rotate(-90) scale(6.66632 1.32916)">
        <stop stop-color="#FFF2D8"/>
        <stop offset="1" stop-color="#FFBF35"/>
        </radialGradient>
        </defs>
    </svg>


</template>
<script>
export default {
    name: 'VipPro',
    props: {
        size: {
            type: Number,
            default: 22,
        },
    },
};
</script>
