<template>
    <svg :width="size" :height="size-4" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0293 20.0001L22.0002 4.33203L12.9719 15.3227L11.0293 20.0001Z" fill="#722ED1"/>
        <path d="M12.9707 15.3223L21.9989 4.33008H17.6343L12.9707 15.3223Z" fill="url(#paint0_radial_74_10949)"/>
        <path d="M15.3044 4.32472L14.4541 1.27734L17.635 4.33053L15.3044 4.32472Z" fill="url(#paint1_radial_74_10949)"/>
        <path d="M11 4.32477L14.4545 1.27734L15.3047 4.32477H11Z" fill="url(#paint2_radial_74_10949)"/>
        <path d="M12.9883 15.3185L15.2863 4.28516L17.6507 4.32757L12.9883 15.3185Z" fill="#9254DE"/>
        <path d="M11.02 0L14.4541 1.27607L10.9994 4.3235L7.5459 1.27436L11.02 0Z" fill="url(#paint3_linear_74_10949)"/>
        <path d="M15.3051 4.32422H11L12.9717 15.3213L15.3051 4.32422Z" fill="url(#paint4_linear_74_10949)"/>
        <path d="M14.4539 1.27613L11.0205 0L17.6547 0.00231402L14.4539 1.27613Z" fill="url(#paint5_linear_74_10949)"/>
        <path d="M20.002 1.26562L17.6357 4.33093H21.9985L20.002 1.26562Z" fill="url(#paint6_radial_74_10949)"/>
        <path d="M14.4541 1.27542L17.6357 4.32963L20.0022 1.26629L17.6553 0.00195312L14.4541 1.27542Z" fill="url(#paint7_radial_74_10949)"/>
        <path d="M11.0293 19.9914L0.000741959 4.32227L9.02906 15.313L11.0293 19.9914Z" fill="#722ED1"/>
        <path d="M9.02832 15.3204L0.000164032 4.32812H4.36476L9.02832 15.3204Z" fill="url(#paint8_radial_74_10949)"/>
        <path d="M6.69563 4.32081L7.5459 1.27344L4.365 4.32662L6.69563 4.32081Z" fill="url(#paint9_radial_74_10949)"/>
        <path d="M11 4.32086L7.54555 1.27344L6.69528 4.32086H11Z" fill="url(#paint10_radial_74_10949)"/>
        <path d="M9.02734 15.3209L6.69476 4.32422L4.3649 4.32995L9.02734 15.3209Z" fill="#9254DE"/>
        <path d="M6.69489 4.32227H11L9.02825 15.3194L6.69489 4.32227Z" fill="url(#paint11_linear_74_10949)"/>
        <path d="M7.54543 1.27381L11.0205 0H4.34465L7.54543 1.27381Z" fill="url(#paint12_linear_74_10949)"/>
        <path d="M1.99804 1.26367L4.36426 4.32898H0.00147676L1.99804 1.26367Z" fill="url(#paint13_radial_74_10949)"/>
        <path d="M7.5459 1.27347L4.36431 4.32767L1.99783 1.26434L4.34474 0L7.5459 1.27347Z" fill="url(#paint14_radial_74_10949)"/>
        <path d="M12.9714 15.3209L10.9996 4.32422L9.02734 15.3193L11.0285 20.0001L12.9714 15.3209Z" fill="url(#paint15_diamond_74_10949)"/>
        <defs>
        <radialGradient id="paint0_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.8577 14.143) rotate(-52.0003) scale(11.6022 9.5289)">
        <stop offset="0.0104167" stop-color="#D9BDF8"/>
        <stop offset="0.466228" stop-color="#B37FEB"/>
        </radialGradient>
        <radialGradient id="paint1_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0446 2.80394) rotate(90) scale(1.52659 1.59045)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <radialGradient id="paint2_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.1524 2.80106) rotate(90) scale(1.52371 2.15236)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <linearGradient id="paint3_linear_74_10949" x1="11" y1="0" x2="11" y2="4.3235" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D3ADF7"/>
        <stop offset="1" stop-color="#F9F0FF"/>
        </linearGradient>
        <linearGradient id="paint4_linear_74_10949" x1="13.1526" y1="4.32422" x2="13.1526" y2="15.3213" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9254DE"/>
        <stop offset="1" stop-color="white"/>
        </linearGradient>
        <linearGradient id="paint5_linear_74_10949" x1="14.3376" y1="-1.39781e-08" x2="14.2862" y2="3.85708" gradientUnits="userSpaceOnUse">
        <stop stop-color="#722ED1"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </linearGradient>
        <radialGradient id="paint6_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.8171 2.79828) rotate(90) scale(1.53265 2.18139)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <radialGradient id="paint7_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.2281 2.16579) rotate(90) scale(2.16384 2.77403)">
        <stop offset="0.0104167" stop-color="#F4F4F4"/>
        <stop offset="1" stop-color="#B37FEB"/>
        </radialGradient>
        <radialGradient id="paint8_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.14131 14.141) rotate(-128) scale(11.6022 9.5289)">
        <stop offset="0.0104167" stop-color="#D9BDF8"/>
        <stop offset="0.466228" stop-color="#B37FEB"/>
        </radialGradient>
        <radialGradient id="paint9_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.95545 2.80003) rotate(90) scale(1.52659 1.59045)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <radialGradient id="paint10_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.84764 2.79715) rotate(90) scale(1.52371 2.15236)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <linearGradient id="paint11_linear_74_10949" x1="8.84745" y1="4.32227" x2="8.84745" y2="15.3194" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9254DE"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint12_linear_74_10949" x1="7.68258" y1="-1.39528e-08" x2="7.73349" y2="3.8501" gradientUnits="userSpaceOnUse">
        <stop stop-color="#722ED1"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </linearGradient>
        <radialGradient id="paint13_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.18287 2.79632) rotate(90) scale(1.53265 2.18139)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#D3ADF7"/>
        </radialGradient>
        <radialGradient id="paint14_radial_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.77186 2.16384) rotate(90) scale(2.16384 2.77403)">
        <stop offset="0.0104167" stop-color="white" stop-opacity="0.55"/>
        <stop offset="1" stop-color="#B37FEB"/>
        </radialGradient>
        <radialGradient id="paint15_diamond_74_10949" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.9994 12.1621) rotate(90) scale(7.83792 1.97202)">
        <stop offset="0.184073" stop-color="#EFDBFF"/>
        <stop offset="1" stop-color="#B37FEB"/>
        </radialGradient>
        </defs>
    </svg>

</template>
<script>
export default {
    name: "VipEnterprise",
    props: {
        size: {
            type: Number,
            default: 22,
        },
    },
};
</script>
